import { CheckCircle, Prohibition } from 'iconoir-react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { FormatMoneyAmount } from '../../../../lib/utils/helpers/get-formated-price';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';

const MinOrder: React.FC<{
  minOrderAmount: number;
  total: number;
  fullVersion: boolean;
}> = ({ minOrderAmount, total, fullVersion }) => {
  const { ct } = useCatalogTranslation('cart');
  const { company } = useCustomerCompany();

  const isMinReached = useMemo(
    () => minOrderAmount - total <= 0,
    [minOrderAmount, total],
  );

  return (
    <div
      className={clsx('text-grey2-60 rounded-xl', {
        'bg-seller/5 py-4 px-6': fullVersion,
        'bg-white px-3 pt-3': !fullVersion,
      })}
      data-testid="minOrder"
    >
      {isMinReached ? (
        <div
          className={clsx('flex items-center', {
            'gap-4': fullVersion,
            'gap-2': !fullVersion,
          })}
        >
          <CheckCircle
            className={clsx('text-seller', {
              'h-8 w-8': fullVersion,
              'h-6 w-6': !fullVersion,
            })}
          />
          <div className="text-sm">
            <span>
              {ct(
                'CartFranco.minOrderRaised',
                'Vous avez atteint votre minimum de commande',
              )}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={clsx('flex items-center ', {
            'gap-4 sm:gap-5': fullVersion,
            'gap-2': !fullVersion,
          })}
        >
          <Prohibition
            className={clsx(' text-grey2--90', {
              'h-8 w-8': fullVersion,
              'h-6 w-6': !fullVersion,
            })}
          />
          <div className="text-sm text-normal">
            {fullVersion && (
              <div>
                <span>
                  {ct(
                    'CartFranco.minOrderAmountIs',
                    'Le montant minimum de commande est de',
                  )}{' '}
                </span>
                <span className="font-bold">
                  {FormatMoneyAmount(
                    minOrderAmount,
                    company.paymentCurrencyCode,
                  )}
                </span>
              </div>
            )}
            <div>
              <span>{ct('CartFranco.remainingAmount_part1', 'Plus que')} </span>
              <span className="font-bold text-seller">
                {FormatMoneyAmount(
                  minOrderAmount - total,
                  company.paymentCurrencyCode,
                )}
              </span>
              <span>
                {' '}
                {ct(
                  'CartFranco.remainingAmount_part2_minOrder',
                  'avant de pouvoir passer votre commande.',
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MinOrder;
