import { CheckCircle, DeliveryTruck } from 'iconoir-react';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { FormatMoneyAmount } from '../../../../lib/utils/helpers/get-formated-price';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useCustomerCompany } from '@lib/hooks/use-customer-company';

const CartFranco: React.FC<{
  franco: number;
  total: number;
  freeShipping: boolean;
  fullVersion: boolean;
}> = ({ franco, total, freeShipping, fullVersion }) => {
  const { ct } = useCatalogTranslation('cart');
  const { company } = useCustomerCompany();

  const isFrancoReached = useMemo(() => franco - total <= 0, [franco, total]);

  return (
    <div
      className={clsx('text-grey2-60 rounded-xl', {
        'bg-seller/5 py-4 px-6': fullVersion,
        'bg-white px-3 pt-3': !fullVersion,
      })}
      data-testid="franco"
    >
      {isFrancoReached ? (
        <div
          className={clsx('flex items-center', {
            'gap-4': fullVersion,
            'gap-2': !fullVersion,
          })}
        >
          <CheckCircle
            className={clsx('text-seller', {
              'h-8 w-8': fullVersion,
              'h-6 w-6': !fullVersion,
            })}
          />
          <div className="text-sm">
            <span>
              {freeShipping
                ? ct(
                    'CartFranco.freeShippingDiscount',
                    'Promtion: la livraison st offerte !',
                  )
                : ct(
                    'CartFranco.raised',
                    'Vous avez atteint votre FRANCO. Profitez de la livraison offerte !',
                  )}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={clsx('flex items-center ', {
            'gap-4 sm:gap-5': fullVersion,
            'gap-2': !fullVersion,
          })}
        >
          <DeliveryTruck
            className={clsx(' text-grey2--90', {
              'h-8 w-8': fullVersion,
              'h-6 w-6': !fullVersion,
            })}
          />
          <div className="text-sm text-normal">
            {fullVersion && (
              <div>
                <span>
                  {ct(
                    'CartFranco.freeShippingFrom',
                    'Livraison offerte à partir de ',
                  )}
                </span>
                <span className="font-bold">
                  {FormatMoneyAmount(franco, company.paymentCurrencyCode)}
                </span>
              </div>
            )}
            <div>
              <span>{ct('CartFranco.remainingAmount_part1', 'Plus que')} </span>
              <span className="font-bold text-seller">
                {FormatMoneyAmount(franco - total, company.paymentCurrencyCode)}
              </span>
              <span>
                {' '}
                {ct(
                  'CartFranco.remainingAmount_part2',
                  'avant la livraison gratuite',
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartFranco;
