import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Menu, Transition } from '@headlessui/react';
import { LANGUAGES } from '../../../../lib/constants';
import ReactCountryFlag from 'react-country-flag';
import { Language } from '../../../../types/global';
import { NavArrowDown } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { languageMapper } from '../../../../lib/utils/helpers/language-mapper';
import { useQueryClient } from '@tanstack/react-query';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    LANGUAGES[0],
  );
  const flagStyles = {
    fontSize: '1.5em',
    lineHeight: '1.5em',
    borderRadius: '6px',
  };

  useEffect(() => {
    if (localStorage && localStorage.getItem('i18nextLng')) {
      let localLanguage = localStorage.getItem('i18nextLng');
      const language = LANGUAGES.find((l) => {
        if (localLanguage.includes('-')) {
          localLanguage = localLanguage.split('-')[0];
        }
        return l.shortLabel.toLowerCase().includes(localLanguage.toLowerCase());
      });
      if (language) {
        langSelected(language);
      }
    }
  }, []);

  const langSelected = (language: Language) => {
    setSelectedLanguage(language);
    const i18nLanguage = languageMapper(language.countryCode);
    if (i18n.language === i18nLanguage) return;
    localStorage.setItem(
      'i18nextLngCde',
      language.langCode ?? language.countryCode,
    );
    i18n.changeLanguage(i18nLanguage);
    queryClient.invalidateQueries();
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div data-testid="translate-switch">
            <Menu.Button
              className={clsx(
                'inline-flex w-full justify-center items-center gap-2 rounded-md px-3 py-2 text-sm font-semibold text-grey2-60',
                open ? 'bg-grey2-5' : 'bg-white',
              )}
            >
              <ReactCountryFlag
                countryCode={selectedLanguage.countryCode}
                style={flagStyles}
                svg
                aria-label={selectedLanguage.shortLabel}
              />
              <span>{selectedLanguage.shortLabel}</span>
              <NavArrowDown className="w-4 h-4 text-grey2-60 font-semibold" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {LANGUAGES.map((language) => (
                  <Menu.Item key={language.label}>
                    {({ active }) => (
                      <button
                        data-testid={`translate-to-${language.label}`}
                        className={clsx(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'group flex items-center px-4 py-2 gap-2 text-sm w-full',
                        )}
                        onClick={() => langSelected(language)}
                      >
                        <ReactCountryFlag
                          countryCode={language.countryCode}
                          style={flagStyles}
                          svg
                          aria-label={language.shortLabel}
                        />
                        <span>{language.shortLabel}</span>
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LanguageDropdown;
