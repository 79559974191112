export const FormatMoneyAmount = (
  value: number,
  currencyCode: string | null | undefined = undefined,
) => {
  if (!isFinite(value) || value === null) return '';

  const rounded = roundTo(value, 6);
  const lang = localStorage?.getItem('i18nextLngCde') ?? 'FR';

  const formater = Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currencyCode ?? 'eur',
  });
  return formater.format(rounded);
};

const roundTo = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};
